<template>
  <div :style="{ position: 'relative' }">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '50vw' }"
      :header="
        (reportId != null ? `Ver Relatório` : `Criar Relatório`) + ` | ` + title
      "
      :modal="true"
      :closable="false"
    >
      <Loading
        :active.sync="loading"
        :isFullPage="false"
        :zIndex="9000"
      ></Loading>
      <form
        name="vehicle-report-form"
        class="p-col-12"
        @submit.prevent="save"
        style="min-height: 20vh"
      >
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-3">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-calendar"></i>
              </span>
              <span class="p-float-label">
                <v-date-picker
                  :disabled="true"
                  v-model="date"
                  :name="'report_date_picker'"
                  :id="'report_date_picker'"
                  :masks="{
                    input: 'YYYY-MM-DD',
                  }"
                >
                  <template v-slot="{ inputValue }">
                    <span class="p-float-label">
                      <input
                        :disabled="true"
                        :name="'fine_date'"
                        :id="'fine_date'"
                        v-bind:class="[
                          'form-control',
                          'p-inputtext',
                          'p-component',
                          'p-filled',
                        ]"
                        :value="inputValue"
                      />
                      <label for="report_date">Data</label>
                    </span>
                  </template>
                </v-date-picker>
              </span>
            </div>
          </div>
          <div class="p-field p-col-12 p-md-3" v-if="reportId">
            <span class="p-float-label">
              <InputText
                :disabled="true"
                type="text"
                v-model="author"
                v-bind:class="['form-control']"
                :id="`author`"
                :name="`author`"
              />
              <label :for="`author`">Autor</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <div class="p-inputgroup">
              <span class="p-float-label">
                <InputNumber
                  :disabled="!editable"
                  v-model="kms"
                  v-bind:class="[
                    {
                      'p-invalid': errors.has('report-kms'),
                    },
                    'form-control',
                  ]"
                  :id="'report-kms'"
                  :name="'report-kms'"
                  v-validate="'required'"
                />
                <label for="report-kms">Km's</label>
              </span>
            </div>
            <small v-if="errors.has(`report-kms`)" class="p-error" role="alert">
              Km's é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-1">
            <span
              class="p-float-label"
              style="top: -0.8rem; left: -1rem; font-size: 12px"
            >
              <label for="closed">{{
                editable == 0 ? "Enviado" : "Enviar"
              }}</label>
            </span>
            <InputSwitch
              :disabled="!editable"
              v-model="closed"
              :name="'closed'"
              :id="'closed'"
              style="'top: 4px;"
            />
          </div>
          <div class="p-field p-col-12 p-md-3" v-if="!editable">
            <span class="p-float-label">
              <InputText
                :disabled="true"
                type="text"
                v-model="sendedAt"
                v-bind:class="['form-control']"
                :id="`sended-at`"
                :name="`sended-at`"
              />
              <label :for="`sended-at`">Data Envio</label>
            </span>
          </div>
        </div>
        <div class="p-grid" v-for="(item, i) in items" :key="i">
          <divider />
          <div class="p-field p-col-12 p-md-5">
            <span class="p-float-label">
              <InputText
                :disabled="!editable"
                type="text"
                v-model="item.title"
                v-validate="'required'"
                v-bind:class="[
                  {
                    'p-invalid': errors.has(`item-title-${i}`),
                  },
                  'form-control',
                  'p-col-12',
                ]"
                :id="`item-title-${i}`"
                :name="`item-title-${i}`"
              />
              <label :for="`item-title-${i}`">Título</label>
            </span>
            <small
              v-if="errors.has(`item-title-${i}`)"
              class="p-error"
              role="alert"
            >
              Título é obrigatório
            </small>
            <span class="p-float-label p-mt-4">
              <Textarea
                :disabled="!editable"
                v-model="item.description"
                v-validate="'required'"
                :autoResize="true"
                rows="3"
                v-bind:class="[
                  {
                    'p-invalid': errors.has(`item-description-${i}`),
                  },
                  'form-control',
                  'p-col-12',
                ]"
                :id="`item-description-${i}`"
                :name="`item-description-${i}`"
              />
              <label :for="`item-description-${i}`">Descrição</label>
            </span>
            <small
              v-if="errors.has(`item-description-${i}`)"
              class="p-error"
              role="alert"
            >
              Descrição é obrigatório
            </small>
          </div>

          <div class="p-field p-col-12 p-md-6" v-if="item.id == undefined">
            <FileUpload
              :ref="`item-files-${item.photo}`"
              :name="`item-files-${item.photo}.files[]`"
              :id="`item-files-${item.photo}`"
              chooseLabel="Adicionar Ficheiros"
              :previewWidth="100"
              :multiple="true"
              :showUploadButton="false"
              :showCancelButton="false"
            />
          </div>
          <div class="p-grid p-col-12 p-md-6 p-d-flex p-jc-center" v-else>
            <div
              v-if="item.photosList.length == 0"
              class="p-d-flex p-ai-center"
            >
              <b>Este item não tem fincheiros associados</b>
            </div>
            <div v-for="doc in item.photosList" :key="doc.id" class="p-mr-2">
              <ImagePreview
                v-if="doc.type != null"
                :src="doc.src"
                alt="Image"
                width="100"
                preview
              />
              <Button
                v-else
                :label="doc.file_origin_name"
                icon="pi pi-download"
                class="p-button-primary"
                @click="download(doc)"
              />
            </div>
          </div>
          <div
            class="p-field p-col-12 p-md-1 p-d-flex p-ai-center"
            v-if="editable"
          >
            <Button
              v-tooltip="'Eliminar Item'"
              icon="pi pi-trash"
              class="p-button-danger"
              @click="removeItem(item)"
            />
          </div>
        </div>
        <div
          class="p-field p-col-12 p-md-12 p-d-flex p-jc-center"
          v-if="editable"
        >
          <Button
            label="Adiciona Item"
            icon="pi pi-plus"
            class="p-button-sm p-md-4"
            @click="addItem"
          />
        </div>
      </form>
      <template #footer>
        <Button
          v-if="editable"
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="save"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import fleetService from "../../services/fleet.service";

export default {
  name: "ReportForm",
  props: ["show", "reportId", "vehicleId", "title"],
  components: { Loading },
  watch: {
    async show() {
      if (this.show === true) {
        this.$validator.resume();
        this.reset();
        if (this.reportId == undefined || this.reportId == null) {
          this.addItem();
        } else {
          this.loading = true;
          await this.getReport();
          await this.processFiles();
          this.loading = false;
        }
      }
    },
  },
  data() {
    return {
      loading: false,
      date: new Date(),
      kms: null,
      closed: false,
      items: [],
      removeditems: [],
      counter: 0,
      editable: true,
      sendedAt: null,
      author: null,
    };
  },
  async created() {},
  methods: {
    reset() {
      this.date = new Date();
      this.kms = null;
      this.sendedAt = null;
      this.author = null;
      this.closed = false;
      this.items = [];
      this.removeditems = [];
      this.counter = 0;
      this.editable = true;
    },
    addItem() {
      this.items.push({
        title: null,
        description: null,
        photo: this.counter++,
      });
    },
    async getReport() {
      this.reset();
      return fleetService
        .getReport(this.vehicleId, this.reportId)
        .then((response) => {
          this.date = new Date(response.created_at);
          this.kms = response.kms;
          this.sendedAt = response.send_date;
          this.author = response.user_created.username;
          this.closed = response.closed == 1 ? true : false;
          if (response.closed == 1) {
            this.editable = false;
          }
          response.items.forEach((item) => {
            this.items.push({
              id: item.id,
              title: item.title,
              description: item.description,
              photosList: item.documents,
              photo: null,
            });
          });
          return;
        });
    },
    async processFiles() {
      for (const item of this.items) {
        for (const file of item.photosList) {
          file.data = await fleetService.getDocument(this.vehicleId, file.id);
          file.type = this.getMimeTypeFromArrayBuffer(file.data);
          if (file.type != null) {
            file.src = `data:${file.type};base64,${this.encodeToBase64(
              file.data
            )}`;
          }
        }
      }
    },
    getMimeTypeFromArrayBuffer(arrayBuffer) {
      const uint8arr = new Uint8Array(arrayBuffer);
      const len = 4;
      if (uint8arr.length >= len) {
        let signatureArr = new Array(len);
        for (let i = 0; i < len; i++)
          signatureArr[i] = new Uint8Array(arrayBuffer)[i].toString(16);
        const signature = signatureArr.join("").toUpperCase();

        switch (signature) {
          case "89504E47":
            return "image/png";
          case "47494638":
            return "image/gif";
          // case "25504446":
          //   return "application/pdf";
          case "FFD8FFDB":
          case "FFD8FFE0":
            return "image/jpeg";
          // case "504B0304":
          //   return "application/zip";
          default:
            return null;
        }
      }
      return null;
    },
    encodeToBase64(arrayBuffer) {
      let binary = "";
      let bytes = new Uint8Array(arrayBuffer);
      let len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    },
    download(doc) {
      let url = window.URL.createObjectURL(new Blob([doc.data]));
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", doc.file_origin_name);
      document.body.appendChild(link);
      link.click();
    },
    removeItem(item) {
      var areYou = null;
      if (item.id == undefined) {
        areYou = (aux) => {
          return aux.photo == item.photo;
        };
      } else {
        areYou = (aux) => {
          return aux.id == item.id;
        };
        this.removeditems.push(item.id);
      }
      let index = this.items.findIndex(areYou);
      if (index >= 0) {
        return this.items.splice(index, 1);
      }
      return;
    },
    cancel() {
      this.$validator.pause();
      this.reset();
      return this.$emit("cancel");
    },
    save() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        this.$validator.pause();
        if (this.reportId == undefined || this.reportId == null) {
          return this.createReport();
        }
        return this.updateReport();
      });
    },
    createReport() {
      let formData = new FormData();
      formData.append("kms", this.kms);
      formData.append("closed", this.closed == true ? 1 : 0);
      let itemCount = 0;
      this.items.forEach((item) => {
        formData.append(`items[${itemCount}][title]`, item.title);
        formData.append(`items[${itemCount}][description]`, item.description);
        let files = this.$refs[`item-files-${item.photo}`][0].files;
        if (files.length > 0) {
          files.forEach((file) => {
            formData.append(`items[${itemCount}][files][]`, file);
          });
        }
        itemCount++;
      });
      this.loading = true;
      return fleetService
        .createReport(this.vehicleId, formData)
        .then((response) => {
          if (response.status >= 400) {
            this.loading = false;
            return this.$toast.add({
              severity: "error",
              summary: "Erro ao Criar relatório",
              detail: response.data.message,
              life: 5000,
            });
          }
          this.$toast.add({
            severity: "success",
            summary: "Relatório Criado",
            detail: "O Relatório Criado com Sucesso",
            life: 5000,
          });

          this.reset();
          return this.$emit("savedReport", {
            report: response.data,
          });
        });
    },
    updateReport() {
      let formData = new FormData();
      formData.append("kms", this.kms);
      formData.append("closed", this.closed == true ? 1 : 0);
      formData.append(`itemsRemove[]`, this.removeditems);
      let itemCount = 0;
      this.items.forEach((item) => {
        if (item.id != undefined) {
          formData.append(`items[${itemCount}][id]`, item.id);
        } else {
          let files = this.$refs[`item-files-${item.photo}`][0].files;
          if (files.length > 0) {
            files.forEach((file) => {
              formData.append(`items[${itemCount}][files][]`, file);
            });
          }
        }
        formData.append(`items[${itemCount}][title]`, item.title);
        formData.append(`items[${itemCount}][description]`, item.description);
        itemCount++;
      });
      this.loading = true;
      return fleetService
        .updateReport(this.vehicleId, this.reportId, formData)
        .then((response) => {
          if (response.status >= 400) {
            this.loading = false;
            return this.$toast.add({
              severity: "error",
              summary: "Erro ao Atualiza Relatório",
              detail: response.data.message,
              life: 5000,
            });
          }
          this.$toast.add({
            severity: "success",
            summary: "Relatório Criado",
            detail: "O Relatório Atualizado com Sucesso",
            life: 5000,
          });

          this.reset();
          return this.$emit("savedReport", {
            report: response.data,
          });
        });
    },
  },
};
</script>
