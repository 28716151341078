<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="`Alterar Condutor ${vehicle ? vehicle.registration : ''}`"
      :modal="true"
      :closable="false"
    >
      <form
        name="change-conductor-form"
        class="p-col-12"
        @submit.prevent="save"
        style="min-height: 60vh"
      >
        <div class="p-fluid p-formgrid p-grid" style="min-height: 20vh">
          <div class="p-field p-col-12 p-md-12">
            <span class="p-float-label">
              <Dropdown
                v-model="selectedEmployee"
                :options="employeesList"
                :filter="true"
                :optionLabel="getSearchLabel"
                :optionValue="'employee_id'"
                v-validate="'required'"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('employee') || invalidConductor,
                  },
                  'form-control',
                ]"
                :name="'employee'"
                :id="'employee'"
              />
              <label for="employee">Condutor</label>
            </span>
            <small v-if="errors.has('employee')" class="p-error" role="alert">
              Condutor é obrigatório
            </small>
            <small v-if="invalidConductor" class="p-error" role="alert">
              O condutor selecionado é igual ao condutor atual
            </small>
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-2">
            <span class="p-float-label">
              <Textarea
                v-model="comments"
                :autoResize="true"
                rows="5"
                v-bind:class="['form-control']"
                :id="'comments'"
                :name="'comments'"
              />
              <label for="comments">Comentários</label>
            </span>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="save"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import "vue-loading-overlay/dist/vue-loading.css";
import unidecode from "unidecode";
import employeeService from "../../services/employee.service";
import fleetService from "../../services/fleet.service";
export default {
  name: "ChangeConductorForm",
  props: ["show", "vehicle"],
  components: {},
  watch: {
    show() {
      if (this.show === true) {
        this.$validator.resume();
      }
      this.selectedEmployee = this.vehicle.current_condutor
        ? this.vehicle.current_condutor.employee_id
        : null;
    },
  },
  data() {
    return {
      loading: true,
      selectedEmployee: null,
      comments: null,
      employeesList: [],
      invalidConductor: false,
    };
  },
  async created() {
    this.employeesList = await employeeService.getActives();
    this.loading = false;
  },
  methods: {
    getSearchLabel(info) {
      if (
        this.vehicle.current_condutor &&
        this.vehicle.current_condutor.employee_id == info.employee_id
      ) {
        return `${unidecode(info.username)} (Condutor Atual)`;
      }
      return `${unidecode(info.username)}`;
    },
    cancel() {
      this.$validator.pause();
      this.comments = null;
      this.selectedEmployee = null;
      return this.$emit("cancel");
    },
    save() {
      this.$validator.validateAll().then((isValid) => {
        this.invalidConductor = false;
        if (!isValid) {
          this.loading = false;
          return;
        }
        if (
          this.vehicle.current_condutor &&
          this.vehicle.current_condutor.employee_id == this.selectedEmployee
        ) {
          return (this.invalidConductor = true);
        }

        let bodyParams = {
          employee_id: this.selectedEmployee,
          comment: this.comments,
        };
        this.$validator.pause();
        fleetService.startUse(this.vehicle.id, bodyParams).then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: `Erro ao Alterar o Condutor`,
              detail: "",
              life: 3000,
            });
          }
          this.$toast.add({
            severity: "success",
            summary: `Condutor Alterado`,
            detail: "",
            life: 3000,
          });
          this.comments = null;
          this.selectedEmployee = null;
          return this.$emit("newConductor", {
            status: "ok",
            currentConductor: response,
          });
        });
      });
    },
  },
};
</script>
